<script setup lang="ts">
import type { Icon } from "../../types/shared";

interface Props {
	name: Icon;
}

const { name } = defineProps<Props>();

const camelize = (s: string) => s.replace(/-./g, (x) => x[1].toUpperCase());
</script>

<template>
	<component :is="camelize(name)" :aria-hidden="true" class="fill-current" v-bind="{ ...$props, ...$attrs }" />
</template>
