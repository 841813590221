<script setup lang="ts">
import { onMounted, ref, useSlots, computed } from "vue";
import type { InputType } from "../../types/input";
import { SvgIcon } from "../../components";
import { KippieTooltip } from "..";

const emit = defineEmits(["update:modelValue", "blur"]);

interface Props {
	modelValue?: any;
	label?: string;
	name?: string;
	type?: InputType;
	placeholder?: string;
	disabled?: boolean;
	required?: boolean;
	focus?: boolean;
	error?: boolean;
	success?: boolean;
	fontBig?: boolean;
	help?: string;
	info?: string;
	min?: number | undefined;
	max?: number | undefined;
	step?: number | undefined;
	cols?: number | undefined;
	rows?: number | undefined;
	maxLength?: number | undefined;
}

const {
	modelValue,
	type = "text",
	name = undefined,
	label = undefined,
	placeholder = undefined,
	disabled = false,
	required = false,
	focus = false,
	error = false,
	success = false,
	fontBig = false,
	help = undefined,
	info = undefined,
	min = undefined,
	max = undefined,
	step = undefined,
	cols = undefined,
	rows = undefined,
	maxLength = undefined
} = defineProps<Props>();

const slots = useSlots();

const input = ref<HTMLInputElement>();

const hasIcon = computed(() => !!slots?.icon);

function start(el: Element): void {
	(el as HTMLDivElement).style.height = el.scrollHeight + "px";
}

function end(el: Element): void {
	(el as HTMLDivElement).style.height = "";
}

const onFocus = () => {
	if (type === "date" && input.value) {
		input.value.type = "date";
	}
};

const onBlur = () => {
	emit("blur");
	if (type === "date" && input.value) {
		input.value.type = "text";
	}
};

onMounted(() => {
	if (focus && input.value) {
		input.value.focus();
	}
	if (type === "date" && input.value) {
		input.value.type = "text";
	}
});

defineExpose({
	inputRef: input
});
</script>

<template>
	<div class="flex flex-col w-full gap-y-2">
		<label
			v-if="label"
			:for="name"
			class="ease-in-out duration-300 flex items-center gap-2"
			:class="{
				'text-red': error && !success,
				'text-xs text-gray': !fontBig,
				'text-base text-black-light font-medium': fontBig
			}"
		>
			<span>
				{{ required ? label + "*" : label }}
			</span>

			<!-- tooltip -->
			<KippieTooltip v-if="info" :info="info" />
		</label>
		<div class="relative">
			<component
				:is="type === 'textarea' ? 'textarea' : 'input'"
				:id="name"
				ref="input"
				:name="name"
				:value="modelValue"
				:type="type"
				:placeholder="placeholder"
				:disabled="disabled"
				:required="required"
				:min="min"
				:max="max"
				:step="step"
				:cols="cols"
				:rows="rows"
				:maxlength="maxLength"
				class="w-full bg-white text-black-light p-4 text-xs border border-brown ease-in-out duration-300 rounded-lg overflow-hidden placeholder:text-gray outline-yellow disabled:cursor-not-allowed appearance-none"
				:class="{
					'!border-red': error && !success,
					'pr-14': (success && !error) || hasIcon,
					'h-14': type !== 'textarea'
				}"
				v-bind="$attrs"
				@input="(v: InputEvent) => $emit('update:modelValue', (v.target as HTMLInputElement).value)"
				@blur="onBlur"
				@focus="onFocus"
			/>
			<Transition
				enter-active-class="duration-300 ease-in-out"
				enter-from-class="opacity-0"
				enter-to-class="opacity-100"
				leave-active-class="duration-300 ease-in-out"
				leave-from-class="topacity-100"
				leave-to-class="opacity-0"
			>
				<div v-if="success && !error" class="absolute right-4 top-1/2 -translate-y-1/2">
					<slot name="icon">
						<SvgIcon name="check" class="text-green" />
					</slot>
				</div>
			</Transition>
		</div>
		<Transition name="expand" @enter="start" @after-enter="end" @before-leave="start" @after-leave="end">
			<p
				v-if="help"
				class="text-xs text-gray ease-in-out duration-300"
				:class="{
					'text-red': error && !success
				}"
			>
				{{ help }}
			</p>
		</Transition>
	</div>
</template>

<style scoped>
.expand-enter-active,.expand-leave-active{@apply duration-200 ease-in-out overflow-hidden}.expand-enter-from,.expand-leave-to{@apply !h-0 opacity-0}
</style>
