<script setup lang="ts">
import type { Schemas } from "#shopware";

const props = defineProps<{
	closeDrawer: () => void;
}>();

const emit = defineEmits<{
	(_e: "selection", _location: Schemas["KwbPickupLocation"]): void;
}>();

const isSettingPickupLocation = ref(false);
const { updateExtension } = useCart();
const { onPickupLocationSelected } = useGtagEvents();

const setPickLocation = async (location: Schemas["KwbPickupLocation"]) => {
	isSettingPickupLocation.value = true;

	await updateExtension({
		pickupLocationId: location.id,
		deliveryTimeSlot: null
	});

	emit("selection", location);
	props.closeDrawer();

	try {
		onPickupLocationSelected(location);
	} catch (error) {
		console.error("Error onPickupLocationSelected", error);
	}

	isSettingPickupLocation.value = false;
};
</script>

<template>
	<StoreLocationSearch only-pickup-locations>
		<template #store-action="{ location }">
			<KippieButton size="small" block @click="setPickLocation(location)" :disabled="isSettingPickupLocation">
				Hier afhalen
			</KippieButton>
		</template>
	</StoreLocationSearch>
</template>
