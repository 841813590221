import type { Schemas } from "#shopware";
import type { GtagItem } from "~/types/gtag";

export const convertLineItemToGtagItem = (
	lineItem: Schemas["OrderLineItem"] | Schemas["LineItem"],
	quantity = 1
): GtagItem => {
	let price = 0;

	if ("price" in lineItem) {
		price = (lineItem as Schemas["LineItem"]).price?.unitPrice || 0;
	} else {
		price = (lineItem as Schemas["OrderLineItem"])?.unitPrice || 0;
	}

	return {
		item_id: lineItem?.payload?.productNumber ?? "kippie-product",
		item_name: lineItem.label || null,
		index: 0,
		item_brand: "Kippie",
		price,
		quantity: lineItem?.quantity || quantity
	};
};

export const convertProductToGtagItem = (product: Schemas["Product"], quantity = 1): GtagItem => {
	const itemListId = product.seoCategory?.name?.toLowerCase().replaceAll(" ", "_") || "";

	return {
		item_id: product.productNumber,
		item_name: product.name,
		item_brand: "Kippie",
		item_category: product.seoCategory?.name || "",
		item_list_id: itemListId,
		item_list_name: product.seoCategory?.name || "",
		price: product.calculatedPrice?.totalPrice,
		quantity: quantity
	};
};
