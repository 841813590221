import type { Address } from "~/types/kippie";

export const formValues = reactive<{
	email: string;
	password: string;
	confirmPassword: string;
	firstName: string;
	lastName: string;
	phone: string;
	birthdate: string;
	isBusiness: boolean;
	businessName: string;
	billingAddress: Partial<Address>;
	newsletter: boolean;
}>({
	email: "",
	password: "",
	confirmPassword: "",
	firstName: "",
	lastName: "",
	phone: "",
	birthdate: "",
	isBusiness: false,
	businessName: "",
	billingAddress: {},
	newsletter: false
});
