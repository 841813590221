import type { Schemas } from "#shopware";
import { useCart as _coreFunctionality } from "@shopware-pwa/composables-next";

const _useCart = () => {
	const coreFunctionality = _coreFunctionality();
	const { apiClient } = useShopwareContext();
	const { open } = useShoppingCartView();
	const { selectedShippingMethod } = useSessionContext();

	const cart = coreFunctionality.cart as unknown as ComputedRef<Schemas["Cart"]>;

	const errors = computed(() => cart.value?.errors || {});

	const hasCrititalErrors = computed(() => Object.values(errors.value).some((error) => error.level === 20));

	const selectedPickupLocation = computed<Schemas["KwbPickupLocation"] | undefined>(
		() => cart.value?.extensions?.pickupLocation as Schemas["KwbPickupLocation"]
	);

	const timeslot = computed(() => cart.value?.extensions?.deliveryTimeSlot);

	// @TODO: Check need for watcher
	watch(
		[selectedShippingMethod, selectedPickupLocation],
		async ([newMethod, newLocation], [oldMethod, oldLocation]) => {
			if (
				(!!oldMethod && newMethod?.id !== oldMethod.id) ||
				(!!oldLocation && newLocation?.id !== oldLocation.id)
			) {
				updateExtension({
					deliveryTimeSlot: null
				});
			}
		}
	);

	const updateExtension = async (extension: any) => {
		// @ts-expect-error
		await apiClient.invoke("updateCartExtension post /checkout/cart", { body: extension });
		coreFunctionality.refreshCart();
	};

	const addProduct = async (params: { id: string; quantity?: number }) => {
		await coreFunctionality.addProduct(params);

		if (Object.keys(errors.value).length) {
			open();
		}
	};

	return {
		...coreFunctionality,
		addProduct,
		cart,
		hasCrititalErrors,
		selectedPickupLocation,
		timeslot,
		updateExtension
	};
};

export const useCart = createSharedComposable(_useCart);
