declare global {
	interface Window {
		Trengo: any;
	}
}

type TrengoCustomField = {
	field_id: number;
	value: string;
};

interface TrengoContactData {
	email: string;
	name: string;
	custom_fields: TrengoCustomField[];
}

type TrengoChatTypes = "chat" | "whatsapp" | "facebook" | "telegram" | "twitter" | "email";

const TRENGO_WIDGET_SCRIPT_URL = "https://static.widget.trengo.eu/embed.js";

export default defineNuxtPlugin({
	name: "trengo",
	parallel: true,
	setup(nuxtApp) {
		const {
			public: { trengo }
		} = useRuntimeConfig();

		const { key } = trengo as { key: string };

		window.Trengo = window.Trengo || {};
		window.Trengo.key = key;
		window.Trengo.render = true;
		window.Trengo.gifPicker = false;
		window.Trengo.filePicker = false;

		const { load } = useScriptTag(
			TRENGO_WIDGET_SCRIPT_URL,
			() => {
				console.timeEnd("trengo:initialized");
			},
			{
				immediate: true,
				async: true,
				type: "text/javascript",
				manual: true
			}
		);

		nuxtApp.hook("app:mounted", () => {
			console.time("trengo:initialized");
			load();
		});

		return {
			provide: {
				setTrengoContactData: (contactData: TrengoContactData) => {
					window.Trengo = window.Trengo || {};
					window.Trengo.contact_data = contactData;
				},
				trengoWidget: {
					open: (type: TrengoChatTypes) => {
						window.Trengo.Api.Widget.open(type);
					}
				}
			}
		};
	}
});
