<script setup lang="ts">
import { breakpointsTailwind } from "@vueuse/core";

const { drawers, activeDrawerComponent } = useDrawer();

const activeModal = computed(() => drawers.value[0]);
const modalIsOpen = computed(() => !!activeModal.value);

const breakPoints = useBreakpoints(breakpointsTailwind);
</script>

<template>
	<KippieDrawer
		:placement="breakPoints.isGreaterOrEqual('lg') ? 'right' : 'bottom'"
		:open="modalIsOpen"
		@close="
			activeDrawerComponent?.onDrawerClose?.();
			activeModal?.props?.closeDrawer();
		"
	>
		<div class="px-5 lg:px-8 flex flex-col gap-6">
			<div class="flex justify-between items-center">
				<Typography variant="h2" v-if="activeModal.props.drawerTitle">
					{{ activeModal.props.drawerTitle }}
				</Typography>

				<KippieIconButton icon="close" @click="activeModal.props.closeDrawer()" />
			</div>

			<div class="grow overflow-auto no-scrollbar">
				<component
					:is="activeModal.component"
					v-bind="activeModal.props"
					v-if="activeModal"
					ref="activeDrawerComponent"
				/>
			</div>
		</div>
	</KippieDrawer>
</template>
