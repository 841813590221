import type { Schemas } from "#shopware";

export const useShopwarePickupLocations = (
	geoLocation?: Ref<{ lat: number; lng: number }>,
	onlyPickupLocations: boolean = false
) => {
	const { apiClient } = useShopwareContext();

	const response = useData(async () => {
		const filters = [];

		if (onlyPickupLocations) {
			filters.push({
				type: "equals",
				field: "isPickupLocation",
				value: true
			} as Schemas["EqualsFilter"]);
		}

		const { data } = await apiClient.invoke("readPickupLocations post /pickup-location", {
			body: {
				filter: filters,
				limit: geoLocation?.value ? 10 : 100,
				latitude: geoLocation?.value?.lat,
				longitude: geoLocation?.value?.lng
			}
		});

		// FIX: Shopware API returns all locations, when coordinates are given because of native mysql query criteria not working
		if (onlyPickupLocations) {
			return data.elements?.filter((item) => item.isPickupLocation === true);
		}

		return data.elements;
	}, []);

	if (geoLocation) {
		watch(geoLocation, () => {
			response.execute();
		});
	}

	return response;
};
