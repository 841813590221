import { useSessionContext as _coreFunctionality } from "@shopware-pwa/composables-next";
import { SHIPPING_METHODS_LIST } from "~/lib/constants";
import type { Schemas } from "#shopware";

const _useSessionContext = (newContext?: Schemas["SalesChannelContext"]) => {
	const coreFunctionality = _coreFunctionality(newContext);
	const { sessionContext } = coreFunctionality;

	const selectedShippingMethod = computed(() => {
		if (!sessionContext.value) return;

		const shippingMethod = SHIPPING_METHODS_LIST.find(
			(method) => method.id === sessionContext.value!.shippingMethod?.id
		);
		if (!shippingMethod) return;

		return {
			...sessionContext.value.shippingMethod,
			...shippingMethod
		};
	});

	return {
		...coreFunctionality,
		selectedShippingMethod
	};
};

export const useSessionContext = createSharedComposable(_useSessionContext);
