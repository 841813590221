import type { Schemas } from "#shopware";
import { convertProductToGtagItem, convertLineItemToGtagItem } from "~/helpers/gtagMapper";

const GTAG_CURRENCY = "EUR";

const gaError = (eventName = "unknown") => {
	console.error(`[GA4][tracker] Something went wrong with the ${eventName} event`);
};

export const useGtagEvents = () => {
	const { refreshSessionContext } = useSessionContext();
	const { cart, totalPrice, refreshCart } = useCart();
	const { selectedShippingMethod, selectedPaymentMethod } = useCheckout();

	const { proxy } = useScriptGoogleAnalytics();

	const onLogin = () => {
		if (!import.meta.client) return;
		try {
			proxy.gtag("event", "login", {
				method: "email"
			});
		} catch {
			gaError("onLogin");
		}
	};

	const onRegister = () => {
		if (!import.meta.client) return;

		try {
			proxy.gtag("event", "register", {
				method: "email"
			});
		} catch {
			gaError("onRegister");
		}
	};

	const onViewItem = (product: Schemas["Product"]) => {
		if (!import.meta.client) return;

		try {
			proxy.gtag("event", "view_item", {
				currency: GTAG_CURRENCY,
				value: product.calculatedPrice?.totalPrice,
				items: [convertProductToGtagItem(product)]
			});
		} catch {
			gaError("onViewItem");
		}
	};

	const onViewItemList = (category: Schemas["Category"], products: Schemas["Product"][]) => {
		if (!import.meta.client) return;

		try {
			const itemListId = category.name?.toLowerCase().replaceAll(" ", "_");
			proxy.gtag("event", "view_item_list", {
				item_list_id: itemListId,
				item_list_name: category.name,
				items: products?.map((product, index: number) => {
					return {
						...convertProductToGtagItem(product),
						index
					};
				})
			});
		} catch {
			gaError("onViewItemList");
		}
	};

	const onSelectItem = (category: Schemas["Category"], product: Schemas["Product"]) => {
		if (!import.meta.client) return;

		try {
			const itemListId = category.name?.toLowerCase().replaceAll(" ", "_");

			proxy.gtag("event", "select_item", {
				item_list_id: itemListId,
				item_list_name: category.name,
				items: [convertProductToGtagItem(product)]
			});
		} catch {
			gaError("onSelectItem");
		}
	};

	const onAddToCart = (product: Schemas["Product"], quantity: number) => {
		if (!import.meta.client) return;

		try {
			proxy.gtag("event", "add_to_cart", {
				currency: GTAG_CURRENCY,
				value: product.calculatedPrice?.totalPrice * quantity,
				items: [convertProductToGtagItem(product, quantity)]
			});
		} catch {
			gaError("onAddToCart");
		}
	};

	const onRemoveFromCart = (lineItem: Schemas["LineItem"], quantity: number) => {
		if (!import.meta.client) return;

		try {
			proxy.gtag("event", "remove_from_cart", {
				currency: GTAG_CURRENCY,
				value: lineItem?.price?.totalPrice,
				items: [convertLineItemToGtagItem(lineItem, quantity)]
			});
		} catch {
			gaError("onRemoveFromCart");
		}
	};

	const onViewCart = () => {
		if (!import.meta.client) return;

		try {
			proxy.gtag("event", "view_cart", {
				currency: GTAG_CURRENCY,
				value: unref(totalPrice),
				items: unref(cart)?.lineItems?.map((lineItem: Schemas["LineItem"], index: number) => {
					return {
						...convertLineItemToGtagItem(lineItem),
						index
					};
				})
			});
		} catch {
			gaError("onViewCart");
		}
	};

	const onBeginCheckout = () => {
		if (!import.meta.client) return;

		try {
			proxy.gtag("event", "begin_checkout", {
				currency: GTAG_CURRENCY,
				value: unref(totalPrice),
				items: unref(cart)?.lineItems?.map((lineItem: Schemas["LineItem"], index: number) => {
					return {
						...convertLineItemToGtagItem(lineItem),
						index
					};
				})
			});
		} catch {
			gaError("onBeginCheckout");
		}
	};

	const onAddShippingInfo = async () => {
		if (!import.meta.client) return;

		try {
			await refreshSessionContext();
			await refreshCart();
			if (!selectedShippingMethod.value) {
				return;
			}

			proxy.gtag("event", "add_shipping_info", {
				currency: GTAG_CURRENCY,
				value: unref(totalPrice),
				shipping_tier: unref(selectedShippingMethod)?.name,
				items: unref(cart).lineItems?.map((lineItem: Schemas["LineItem"], index: number) => {
					return {
						...convertLineItemToGtagItem(lineItem),
						index
					};
				})
			});
		} catch {
			gaError("onAddShippingInfo");
		}
	};

	const onAddPaymentInfo = async () => {
		if (!import.meta.client) return;

		try {
			await refreshSessionContext();
			await refreshCart();
			if (!selectedPaymentMethod.value) {
				return;
			}

			proxy.gtag("event", "add_payment_info", {
				currency: GTAG_CURRENCY,
				value: unref(totalPrice),
				payment_type: unref(selectedPaymentMethod)?.name,
				items: unref(cart)?.lineItems?.map((lineItem: Schemas["LineItem"], index: number) => {
					return {
						...convertLineItemToGtagItem(lineItem),
						index
					};
				})
			});
		} catch {
			gaError("onAddPaymentInfo");
		}
	};

	const onPurchase = (order: Schemas["Order"]) => {
		if (!import.meta.client) return;

		try {
			proxy.gtag("event", "purchase", {
				transaction_id: order.orderNumber,
				value: order.amountTotal,
				tax: (order.amountTotal ?? 0) - (order.amountNet ?? 0),
				shipping: order.shippingTotal,
				currency: GTAG_CURRENCY,
				items: order.lineItems?.map((lineItem: Schemas["OrderLineItem"], index: number) => {
					return {
						...convertLineItemToGtagItem(lineItem, lineItem.quantity),
						index
					};
				})
			});
			proxy.gtag("event", "conversion", {
				send_to: "AW-922717051/40rPCMKV8IcYEPuW_rcD",
				currency: GTAG_CURRENCY,
				transaction_id: order.orderNumber
			});
		} catch {
			gaError("onPurchase");
		}
	};

	const onPickupLocationSelected = (pickupLocation: Schemas["KwbPickupLocation"]) => {
		if (!import.meta.client) return;

		try {
			proxy.gtag("event", "store_selection", {
				event_category: "ecommerce",
				event_label: pickupLocation.name
			});
		} catch {
			gaError("onPickupLocationSelected");
		}
	};

	return {
		onLogin,
		onRegister,
		onViewItemList,
		onSelectItem,
		onViewItem,
		onAddToCart,
		onRemoveFromCart,
		onViewCart,
		onBeginCheckout,
		onAddShippingInfo,
		onAddPaymentInfo,
		onPurchase,
		onPickupLocationSelected
	};
};
