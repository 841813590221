import account from "./account.vue";
import arrowBack from "./arrow-back.vue";
import checkRound from "./check-round.vue";
import check from "./check.vue";
import chevronDown from "./chevron-down.vue";
import chevronLeft from "./chevron-left.vue";
import chevronRight from "./chevron-right.vue";
import chevronUp from "./chevron-up.vue";
import chicken from "./chicken.vue";
import circleIcon from "./circle-icon.vue";
import clock from "./clock.vue";
import close from "./close.vue";
import delivery from "./delivery.vue";
import double from "./double.vue";
import egg from "./egg.vue";
import envelope from "./envelope.vue";
import facebook from "./facebook.vue";
import filterIcon from "./filter-icon.vue";
import hamburger from "./hamburger.vue";
import heartFilled from "./heart-filled.vue";
import heart from "./heart.vue";
import ideal from "./ideal.vue";
import info from "./info.vue";
import instagram from "./instagram.vue";
import kippie from "./kippie.vue";
import leaf from "./leaf.vue";
import location from "./location.vue";
import minus from "./minus.vue";
import moon from "./moon.vue";
import pageInfo from "./page-info.vue";
import paypal from "./paypal.vue";
import percent from "./percent.vue";
import phone from "./phone.vue";
import placeholder from "./placeholder.vue";
import plus from "./plus.vue";
import search from "./search.vue";
import shoppingBag from "./shopping-bag.vue";
import single from "./single.vue";
import star from "./star.vue";
import store from "./store.vue";
import sun from "./sun.vue";
import sunrise from "./sunrise.vue";
import tiktok from "./tiktok.vue";
import trash from "./trash.vue";
import visa from "./visa.vue";
import puzzle from "./puzzle.vue";
export {
  account,
  arrowBack,
  checkRound,
  check,
  chevronDown,
  chevronLeft,
  chevronRight,
  chevronUp,
  chicken,
  circleIcon,
  clock,
  close,
  delivery,
  double,
  egg,
  envelope,
  facebook,
  filterIcon,
  hamburger,
  heartFilled,
  heart,
  ideal,
  info,
  instagram,
  kippie,
  leaf,
  location,
  minus,
  moon,
  pageInfo,
  paypal,
  percent,
  phone,
  placeholder,
  plus,
  search,
  shoppingBag,
  single,
  star,
  store,
  sun,
  sunrise,
  tiktok,
  trash,
  visa,
  puzzle
};
