<script setup lang="ts">
import { twMerge } from "tailwind-merge";
import * as pressable from "@zag-js/pressable";
import { normalizeProps, useMachine } from "@zag-js/vue";
import { computed } from "vue";
import type { ButtonColor, Justify, ButtonSize } from "../";
import KippieLoader from "./KippieLoader.vue";

interface Props {
	shadow?: boolean;
	block?: boolean;
	size?: ButtonSize;
	pill?: boolean;
	disabled?: boolean;
	fake?: boolean;
	color?: ButtonColor;
	justify?: Justify;
	loading?: boolean;
}

const {
	block,
	loading,
	fake,
	pill,
	size = "default",
	shadow = true,
	color = "yellow",
	justify = "center",
	disabled
} = defineProps<Props>();

const [state, send] = useMachine(
	pressable.machine({
		// generates random id for every component
		id: (Math.random() + 1).toString(36).substring(7),
		disabled
	})
);

const api = computed(() => pressable.connect(state.value, send, normalizeProps));

const buttonSizeClasses: Record<ButtonSize, string> = {
	small: "text-sm px-6 gap-2 h-11.5",
	default: "text-sm sm:text-base gap-3.5 px-6 h-13",
	large: "text-base gap-4 h-16 px-6"
};
</script>

<template>
	<component
		v-bind="api.pressableProps"
		:is="fake ? 'div' : 'button'"
		:disabled="disabled || loading"
		class="font-bold flex items-center disabled:opacity-70 disabled:cursor-not-allowed outline-none hover:scale-[102%] duration-200 ease-in-out sm:whitespace-nowrap rounded overflow-hidden relative"
		:class="
			twMerge([
				buttonSizeClasses[size],
				pill && 'py-[10px] px-6 rounded-full',
				block && 'w-full',
				fake && !block && 'w-fit',
				api.isPressed && !disabled && 'translate-y-[2px]',
				(loading || disabled) && fake && 'cursor-not-allowed opacity-70',
				color === 'yellow' && 'bg-yellow text-black-light',
				color === 'black' && 'bg-black-light text-white',
				color === 'white' && 'bg-white text-black-light border border-brown',
				shadow && 'shadow-inset-light'
			])
		"
		:style="{ justifyContent: justify }"
	>
		<slot name="left" />
		<span v-if="loading" class="absolute left-1/2 -translate-x-1/2"><KippieLoader /></span>
		<span class="my-[2px] relative grow flex overflow-hidden">
			<span
				class="transition-opacity truncate grow"
				:class="{
					'opacity-0': loading,
					'text-center': justify !== 'space-between',
					'text-left': justify === 'space-between'
				}"
			>
				<slot />
			</span>
		</span>
		<slot name="right" />
	</component>
</template>
