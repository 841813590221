import { PickupLocationPrompt } from "#components";

export const useDeliveryParameters = () => {
	if (!import.meta.client) return;

	const route = useRoute();
	const { showDrawer } = useDrawer();
	const { setShippingMethod, selectedShippingMethod } = useSessionContext();

	watch(
		() => route.query,
		async () => {
			const { dmethod } = route.query as { dmethod: string };

			if (!dmethod) return;

			// delivery 018a9309f14b700297a1002cd96162fe
			// pickup 018a9309f14b700297a1002cd8616773

			try {
				await setShippingMethod({ id: dmethod });

				if (selectedShippingMethod.value?.technicalName === "pickup") {
					showDrawer(PickupLocationPrompt, {
						drawerTitle: "Selecteer je afhaallocatie"
					});
				}
			} catch (error) {
				console.error("something went wrong while setting the shipping method", error);
				return;
			}
		},
		{
			immediate: true
		}
	);
};
