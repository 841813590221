<script setup lang="ts">
import { onKeyStroke } from "@vueuse/core";
import type { Placement } from "../";

const emit = defineEmits(["close"]);
interface Props {
	open: boolean;
	placement?: Placement;
	disableClickAway?: boolean;
	disableEsc?: boolean;
}

const { open, placement = "bottom", disableClickAway = false, disableEsc = false } = defineProps<Props>();

onKeyStroke("Escape", () => close("esc"));

const animations = {
	left: "-translate-x-full",
	right: "translate-x-full",
	top: "-translate-y-full",
	bottom: "translate-y-full"
};

function close(type: "click" | "esc"): void {
	if ((type === "click" && !disableClickAway) || (type === "esc" && !disableEsc)) {
		emit("close");
	}
}
</script>

<template>
	<Teleport to="body">
		<Transition
			enter-active-class="duration-300 ease-in-out"
			enter-from-class="opacity-0"
			enter-to-class="opacity-100"
			leave-active-class="duration-300 ease-in-out"
			leave-from-class="opacity-100"
			leave-to-class="opacity-0"
		>
			<div
				v-if="open"
				class="fixed z-20 inset-0 bg-black/50"
				:class="{ 'cursor-pointer': !disableClickAway }"
				@click="close('click')"
			/>
		</Transition>
		<Transition
			:enter-from-class="`${animations[placement]} opacity-0`"
			enter-to-class="translate-0 opacity-100"
			enter-active-class="duration-300 ease-in-out"
			leave-from-class="translate-0 opacity-100"
			:leave-to-class="`${animations[placement]} opacity-0`"
			leave-active-class="duration-300 ease-in-out"
		>
			<div
				v-if="open"
				tabindex="-1"
				class="fixed z-50 bg-white pt-6 pb-6 outline-yellow lg:w-[470px] no-scrollbar overflow-auto"
				:class="{
					'left-0 right-0 bottom-0 rounded-t-xl lg:rounded-none max-h-[calc(100vh-4rem)] lg:max-h-screen':
						placement === 'bottom',
					'left-0 right-0 top-0 rounded-b-xl lg:rounded-none max-h-[calc(100vh-4rem)] lg:max-h-screen ':
						placement === 'top',
					'bottom-0 top-0 left-0 rounded-r-xl lg:rounded-none': placement === 'left',
					'bottom-0 top-0 right-0 rounded-l-xl lg:rounded-none': placement === 'right'
				}"
			>
				<slot />
			</div>
		</Transition>
	</Teleport>
</template>
