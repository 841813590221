<script setup lang="ts">
import { normalizeProps, useMachine } from "@zag-js/vue";
import * as tooltip from "@zag-js/tooltip";
import { computed } from "vue";
import { SvgIcon } from "..";

interface Props {
	info?: string;
}

const { info } = defineProps<Props>();

const [state, send] = useMachine(
	tooltip.machine({
		id: (Math.random() + 1).toString(36).substring(7),
		positioning: { placement: "right" },
		openDelay: 0,
		closeDelay: 0
	})
);

const api = computed(() => tooltip.connect(state.value, send, normalizeProps));
</script>

<template v-if="info">
	<div class="relative h-5 w-5">
		<button v-bind="api.triggerProps">
			<SvgIcon name="info" class="text-gray" />
		</button>
		<div v-if="api.isOpen" class="bg-black/50 rounded-lg p-2 z-[1] text-xs text-white" v-bind="api.positionerProps">
			<div v-bind="api.contentProps">{{ info }}</div>
		</div>
	</div>
</template>
