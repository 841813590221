<script setup lang="ts">
const { snackbars } = useSnackbar();
</script>

<template>
	<div class="fixed z-40 left-2 right-2 bottom-6">
		<TransitionGroup
			enter-active-class="duration-300 ease-in-out"
			enter-from-class="translate-y-full opacity-0"
			enter-to-class="translate-y-0 opacity-100"
			leave-active-class="duration-300 ease-in-out absolute"
			leave-from-class="translate-y-0 opacity-100"
			leave-to-class="translate-y-full translate-x-full opacity-0"
			move-class="transition-all duration-300"
		>
			<KippieSnackbar
				class="mt-2"
				v-for="snackbar in snackbars"
				:key="snackbar.content"
				:content="snackbar.content"
				:icon="snackbar.icon"
				:icon-color="snackbar.iconColor"
			/>
		</TransitionGroup>
	</div>
</template>
