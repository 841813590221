import { formatDistance, format } from "date-fns";
import { nl } from "date-fns/locale";

export const formatPrice = (price: string | number = 0, showCurrencySign: boolean = false) =>
	Intl.NumberFormat("nl-NL", {
		style: showCurrencySign ? "currency" : "decimal",
		currency: "EUR",
		minimumFractionDigits: 2,
		maximumFractionDigits: 2
	}).format(Number(price));

export const formatLocalDate = (date: string) => format(new Date(date), "PP", { locale: nl });

export const formatDateDistance = (date: string = "1970-1-1") =>
	formatDistance(new Date(date), new Date(), { addSuffix: true, locale: nl });

export const formatDate = (date: string = "1970-1-1") =>
	format(new Date(date), "eeee d MMMM", { locale: nl }).replace(/^./, (c) => c.toUpperCase());
export const formatDayOfWeek = (date: string = "1970-1-1") => format(new Date(date), "EEEEEE", { locale: nl });
export const formatDayOfMonth = (date: string = "1970-1-1") => format(new Date(date), "dd MMM", { locale: nl });
export const formatTime = (date: string = "1970-1-1") => format(new Date(date), "HH:mm", { locale: nl });

export const formatTimeString = (time: string = "00:00:00") => {
	const [hours, minutes] = time.split(":");
	return `${hours}:${minutes}`;
};
